import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { CmsUser } from 'src/app/auth/models';

@Component({
    selector: 'app-user-avatar',
    imports: [],
    templateUrl: './user-avatar.component.html',
    styleUrl: './user-avatar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  user = input.required<Partial<CmsUser>>();

  initials = computed(() => {
    return this.getInitials(this.user().fullName || this.user().firstName + ' ' + this.user().lastName);
  });

  getInitials(fullName: string) {
    let nameParts = fullName.split(/\s+/).filter(name => name.length > 0);
    if (nameParts.length === 0) return '';
    let firstInitial = nameParts[0][0].toUpperCase(); // Eerste letter van de voornaam
    let lastInitial = nameParts[nameParts.length - 1][0].toUpperCase(); // Eerste letter van de achternaam
    return [firstInitial, lastInitial].join('');
  }
}
